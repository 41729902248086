
















































import { Nullable } from '@/types'
import { Component, Prop } from 'vue-property-decorator'
import { PremiumPlacementModel } from '../models/PremiumModel'
import { namespace } from 'vuex-class'
const PlaceTypesStore = namespace('PlaceTypesStore')
const PlansStore = namespace('PlansStore')
import { PlaceTypesModel } from '@/core/models/PlaceTypesModel'
import { PlanModel } from '@/core/models/PlanModel'
import { toLocalCurrency } from '@/utils/currencyFormatter'
import PremiumService from '../PremiumService'
import BaseCheckbox from '@/components/forms/BaseCheckbox.vue'
import Spinner from '@/components/Spinner.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'

import { TippyComponent } from 'vue-tippy/dist/vue-tippy.esm'
import { BreakpointsMixin } from '@/mixins/breakpoints.mixin'

@Component({
  name: 'PremiumPlacement',
  components: {
    BaseCheckbox,
    Spinner,
    BaseIcon,
    TippyComponent,
  },
})
export default class extends BreakpointsMixin {
  @Prop({ required: true })
  placement!: PremiumPlacementModel

  @PlansStore.Getter
  public planList!: PlanModel[]

  @PlaceTypesStore.Getter
  public placeTypesList!: PlaceTypesModel[]

  id = 0
  isAutoRenew = false
  loading = false
  baseUrl = process.env.VUE_APP_URL
  toLocalCurrency = toLocalCurrency

  created(): void {
    this.id = this.placement.id
    this.isAutoRenew = this.placement.auto_renew
  }

  get сategory(): Nullable<PlaceTypesModel> {
    return (
      this.placeTypesList.find(
        (item) => item.id === this.placement.place_type
      ) || null
    )
  }

  get plan(): Nullable<PlanModel> {
    return (
      this.planList.find((item) => item.id === this.placement.billing_plan) ||
      null
    )
  }

  async toggle(value: boolean): Promise<void> {
    this.loading = true
    try {
      const placement = await PremiumService.autorenew(this.id, value)
      if (typeof placement.auto_renew === 'boolean') {
        this.placement.auto_renew = placement.auto_renew
        this.isAutoRenew = placement.auto_renew
      } else {
        this.isAutoRenew = this.placement.auto_renew
      }
    } catch (error) {
      this.isAutoRenew = this.placement.auto_renew
    }
    this.loading = false
  }
}
