import axios from '@/api/axios'
import {
  PremiumModel,
  PremiumAddModel,
  PremiumPlacementModel,
  PremiumAddResponseModel,
  PremiumUpdateInfoModel,
  PremiumUpdateInfoResponseModel,
} from './models/PremiumModel'

class PremiumService {
  async getCurrent(): Promise<PremiumModel[]> {
    try {
      const response = await axios.get('api/b2b/v1/premium/current')
      return response.data.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('getCurrent', error.message, error.response?.data)
      }
      throw error
    }
  }

  async premiumAdd(premium: PremiumAddModel): Promise<PremiumAddResponseModel> {
    try {
      const response = await axios.post('api/b2b/v1/premium/add', premium)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('premiumAdd', error.message, error.response?.data)
      }
      throw error
    }
  }

  async premiumGetPartner(id: number): Promise<any> {
    try {
      const response = await axios.get(`api/common/v1/places/partner/${id}`)
      return response.data.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('getPartnerPlaces', error.message, error.response?.data)
      }
      throw error
    }
  }

  async premiumUpdateInfo(premium: PremiumUpdateInfoModel): Promise<PremiumUpdateInfoResponseModel> {
    try {
      // const response = await axios.post('api/b2b/v1/premium/update-info', premium)

      const bodyFormData = new FormData();

      bodyFormData.append('description', premium.description)
      bodyFormData.append('logo_image', premium.logoImage)
      bodyFormData.append('background_image', premium.backgroundImage)

      const response:any = axios({
        method: "post",
        url: 'api/b2b/v1/premium/update-info',
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('premiumUpdateInfo', error.message, error.response?.data)
      }
      throw error
    }
  }

  async autorenew(id: number, value: boolean): Promise<PremiumPlacementModel> {
    try {
      const response = await axios.post(`api/b2b/v1/premium/${id}/autorenew`, {
        value,
      })
      return response.data.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('autorenew', error.message, error.response?.data)
      }
      throw error
    }
  }

  isServiceError = axios.isAxiosError
}

export default new PremiumService()
